<template>
    <b-row>
        <b-col>
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row v-if="manual === 0">
                        <b-col cols="12" class="text-left mb-2">
                            <b-badge :variant="statusVariant(this.status)">
                                <span v-if="this.status === 1">Waiting Approval</span>
                                <span v-else-if="this.status === 2">Approved</span>
                                <span v-else>Rejected</span>
                            </b-badge>
                        </b-col>
                        <b-col cols="3">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                    label="Image"
                                    label-for="image"
                                    >
                                        <b-img id="photo" :src="photo" fluid alt="attendance photo" v-b-modal.modal-lg />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-modal
                            id="modal-lg"
                            ok-only
                            ok-title="close"
                            centered
                            size="lg"
                            title="Large Modal"
                            class="text-center"
                            >
                                <b-img id="photo" :src="photo" width="1000px" fluid alt="attendance photo" />
                            </b-modal>
                        </b-col>
                        <b-col cols="9">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                    label="Employee Name"
                                    label-for="attendances"
                                    >
                                        <b-form-input
                                            id="attendances"
                                            placeholder="Employee Name"
                                            v-model="form.name"
                                            disabled
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                    label="Start At"
                                    label-for="start_at"
                                    >
                                        <flat-pickr
                                        id="start_at"
                                        v-model="form.start_at"
                                        class="form-control"
                                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                                        :disabled="this.status !== 1? true : false"
                                        required
                                        />
                                        <!-- <p>Value: '{{ form.start_at }}'</p> -->
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                    label="End At"
                                    label-for="end_at"
                                    >
                                        <flat-pickr
                                        id="end_at"
                                        v-model="form.end_at"
                                        class="form-control"
                                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                                        :disabled="this.status !== 1? true : false"
                                        required
                                        />
                                        <!-- <p>Value: '{{ form.end_at }}'</p> -->
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                    label="Duration"
                                    label-for="duration"
                                    >
                                        <b-form-input
                                            id="duration"
                                            placeholder="Duration"
                                            v-model="form.duration"
                                            disabled
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group
                                    label="Location"
                                    label-for="location"
                                    >
                                        <b-form-textarea
                                            id="location"
                                            placeholder="Location"
                                            v-model="form.location"
                                            disabled
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group
                                    label="Note"
                                    label-for="note"
                                    >
                                        <b-form-textarea
                                            id="note"
                                            placeholder="Note"
                                            v-model="form.note"
                                            :disabled="this.status !== 1? true : false"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                            label="Maps"
                            label-for="maps"
                            >
                                <iframe :src="'https://maps.google.com/maps?q='+form.latitude+','+form.longitude+'&hl=eng&z=14&amp;output=embed'" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" v-if="this.status === 3">
                            <b-form-group
                            label="Reject Reason"
                            label-for="reject_reason"
                            label-cols-md="2"
                            >
                            <div>
                                <b-form-input
                                    id="reject_reason"
                                    placeholder="Reject Reason"
                                    v-model="form.reject_reason"
                                    disabled
                                />

                            </div>
                            </b-form-group>
                        </b-col>

                        <!-- update and reset -->
                        <b-col md="12" class="mt-2" v-if="status === 1">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="updateAttendance()"
                            :disabled="form.end_at === ''"
                            block
                            >
                            Update
                            </b-button>
                        </b-col>
                    </b-row>
                    <b-row v-else>
                        <b-col cols="12" class="text-left mb-2">
                            <b-badge :variant="statusVariant(this.status)">
                                <span v-if="this.status === 1">Waiting Approval</span>
                                <span v-else-if="this.status === 2">Approved</span>
                                <span v-else>Rejected</span>
                            </b-badge>
                            -
                            <b-badge>
                                <span v-if="manual === 1">Manual</span>
                            </b-badge>
                        </b-col>
                        <b-col cols="12">
                            <b-row>
                                <b-col cols="12">
                                    <b-form-group
                                    label="Employee Name"
                                    label-for="attendances"
                                    >
                                        <b-form-input
                                            id="attendances"
                                            placeholder="Employee Name"
                                            v-model="form.name"
                                            disabled
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                    label="Start At"
                                    label-for="start_at"
                                    >
                                        <flat-pickr
                                        id="start_at"
                                        v-model="form.start_at"
                                        class="form-control"
                                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                                        :disabled="this.status !== 1? true : false"
                                        required
                                        />
                                        <!-- <p>Value: '{{ form.start_at }}'</p> -->
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                    label="End At"
                                    label-for="end_at"
                                    >
                                        <flat-pickr
                                        id="end_at"
                                        v-model="form.end_at"
                                        class="form-control"
                                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                                        :disabled="this.status !== 1? true : false"
                                        required
                                        />
                                        <!-- <p>Value: '{{ form.end_at }}'</p> -->
                                    </b-form-group>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-group
                                    label="Duration"
                                    label-for="duration"
                                    >
                                        <b-form-input
                                            id="duration"
                                            placeholder="Duration"
                                            v-model="form.duration"
                                            disabled
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group
                                    label="Location"
                                    label-for="location"
                                    >
                                        <b-form-textarea
                                            id="location"
                                            placeholder="Location"
                                            v-model="form.location"
                                            disabled
                                        />
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <b-form-group
                                    label="Note"
                                    label-for="note"
                                    >
                                        <b-form-textarea
                                            id="note"
                                            placeholder="Note"
                                            v-model="form.note"
                                            :disabled="this.status !== 1? true : false"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" v-if="this.status === 3">
                            <b-form-group
                            label="Reject Reason"
                            label-for="reject_reason"
                            label-cols-md="2"
                            >
                            <div>
                                <b-form-input
                                    id="reject_reason"
                                    placeholder="Reject Reason"
                                    v-model="form.reject_reason"
                                    disabled
                                />

                            </div>
                            </b-form-group>
                        </b-col>

                        <!-- update and reset -->
                        <b-col md="12" class="mt-2" v-if="status === 1">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="updateAttendance()"
                            :disabled="form.end_at === ''"
                            block
                            >
                            Update
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
        <b-col cols="12" md="12" class="mt-0" v-if="status === 1 && storage !== null">
            <b-row>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="danger"
                    class="mr-1"
                    block
                    v-b-modal.modal-reject
                    >
                    Reject
                    </b-button>
                </b-col>
                <b-col>
                    <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="success"
                    class="mr-1"
                    block
                    @click="approve()"
                    >
                    Approve
                    </b-button>
                </b-col>
            </b-row>
        </b-col>
        <!-- modal reject -->
        <b-modal
        id="modal-reject"
        title="Reject Form"
        ok-title="Reject"
        cancel-variant="outline-secondary"
        hide-footer
        >
            <validation-observer ref="loginValidation">
            <b-form ref="form" @submit.prevent>
                <b-form-group
                :state="errors"
                label="Reject Reason"
                label-for="reject"
                invalid-feedback="Reject Reason required"
                >
                    <validation-provider
                    #default="{ errors }"
                    name="reject"
                    rules="required"
                    >
                        <b-form-input
                            id="reject"
                            name="reject"
                            type="text"
                            v-model="form.reject_reason"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Reject Reason"
                            required="required"
                        />
                        <small class="text-danger">{{ errors[0] || errMessage }}</small>
                    </validation-provider>
                </b-form-group>

                <b-row>
                    <b-col>
                        <b-button
                            class="mt-3"
                            type="submit"
                            variant="danger"
                            block
                            @click="reject()"
                        >
                            Reject
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
            </validation-observer>
        </b-modal>
        <!-- end modal reject -->
    </b-row>
</template>

<script>
import {
    BFormTextarea, BBadge, BImg, BFormTimepicker, BFormDatepicker, BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from "@themeConfig"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    LMap, LTileLayer, LMarker, LPopup
} from 'vue2-leaflet'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        ToastificationContent,
        flatPickr,
        BFormTextarea,
        BBadge,
        ValidationProvider,
        ValidationObserver,
        LMap,
        LTileLayer,
        LMarker,
        LPopup,
        BImg,
        BFormTimepicker,
        BFormDatepicker,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        draggable,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                name: '',
                start_at: null,
                end_at: null,
                start_at: null,
                end_at: null,
                duration: null,
                note: '',
                location: '',
                latitude: '',
                longitude: '',
                reject_reason: ''
            },
            storage: null,
            manual: 0,
            photo: '',
            maps: '',
            status: '',
            errors: '',
            errMessage: ''
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                1 : 'light-warning',
                2 : 'light-success',
                3 : 'light-danger'
            }

            return status => statusColor[status]
        },
    },
    setup() {
        // App Name
        const { baseUrl, apiUrl } = $themeConfig.app;
        return {
            baseUrl,
            apiUrl
        };
    },
    created() {
        this.getDetails()
    },
    methods: {
        getDetails() {
            const id = this.$route.params.id
            this.$http
            .get('attendances/' +id)
            .then((response) => {
                // console.log(response.data.data)
                this.status = response.data.data.status
                this.form.name = response.data.data.user_name
                this.form.start_at = response.data.data.start_at
                this.form.end_at = response.data.data.end_at
                this.form.duration = response.data.data.duration
                this.form.note = response.data.data.note
                const { baseUrl } = $themeConfig.app
                this.photo = baseUrl
                this.photo += 'storage/attendance/'
                this.photo += response.data.data.image
                // console.log(this.photo)
                this.form.location = response.data.data.location
                this.form.latitude = response.data.data.latitude
                this.form.longitude = response.data.data.longitude
                this.form.reject_reason = response.data.data.reject_reason
                this.storage = response.data.data.end_at
                if(response.data.data.end_at === '') {
                    this.storage = null
                } else {
                    this.storage = response.data.data.end_at
                }
                this.manual = response.data.data.is_manual_time
            }).catch((err) => {
                console.log(err.response)
            });
        },
        updateAttendance() {
            const id = this.$route.params.id
            this.$http
            .post('attendances/'+id+'/update', {
                'name': this.form.name,
                'start_at': this.form.start_at,
                'end_at': this.form.end_at,
                'note': this.form.note,
                'photo': this.photo
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Success update Attendance',
                        variant: 'success',
                    },
                })
                location.href = "/attendances"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        approve() {
            const id = this.$route.params.id
            this.$http
            .post('attendances/'+id+'/approve')
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Attendance Approved',
                        variant: 'success',
                    },
                })
                location.href = "/attendances"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
        reject() {
            const id = this.$route.params.id
            this.$http
            .post('attendances/'+id+'/reject', {
                'reject_reason': this.form.reject_reason
            })
            .then((response) => {
                // console.log(response.data.data)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: 'Attendance Rejected',
                        variant: 'danger',
                    },
                })
                location.href = "/attendances"
            }).catch((errors) => {
                this.errMessage = errors.response.data.message
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        text: this.errMessage,
                        variant: 'warning',
                    },
                })
                console.log(errors.response)
            });
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>